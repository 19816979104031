import React from "react";
import { Tag, Tooltip } from "antd";
import ModalSMS from "../Modal/ModalSMS";
import "firebase/firestore";

export const CellTelephone = ({ cell, magasin }) => {
  function dateToString() {
    console.log(cell.row.original.smsDate._seconds);
    let timestamp = cell.row.original.smsDate;
    if (timestamp === undefined) return "pas de date";
    const date =
      (timestamp._seconds + timestamp._nanoseconds * 10 ** -9) * 1000;
    return new Date(date).toLocaleDateString("fr-FR", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return (
    <div style={{ textAlign: "center" }}>
      {!cell.row.original.sms ? (
        <ModalSMS resa={cell.row.original} magasin={magasin} />
      ) : (
        <Tooltip placement="top" title={dateToString()}>
          <Tag color="magenta" className="tag-cell-phone">
            <span role="img" aria-labelledby="phone">
              📱
            </span>{" "}
            ENVOYE
          </Tag>
        </Tooltip>
      )}
    </div>
  );
};
